// vue
import { ref, reactive, computed, watch, onMounted } from 'vue';

// services
import Marketplace from '@services/marketplace';
import LocalStorage from '@services/ts/localStorage';

// shared
import { CRUD_PAGER_FACTORY } from '@shared/factories';

// i18n
import { useI18n } from 'vue-i18n';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useMarketplace } from '@store/ts/marketplace';

// naive ui
import { useMessage } from 'naive-ui';

export default function (props, context) {
    // store
    const gl = useGl();
    const refs = useRefs();
    const marketplace = useMarketplace();

    // i18n
    const { t } = useI18n();

    // naive-ui
    

    // vars
    const { _ } = window;
    const showInfoModal = ref(false);
    const init = ref(false);
    const dataRef = ref();
    const iAcceptIt = ref(false);
    const currentSignal = ref();
    const signalsDetails = ref();

    // storage filters
    const storeFilterKey = '___filters_marketplace-my-signals';
    let storeFilters = LocalStorage.getItem(storeFilterKey);

    // set filter settings
    if (storeFilters == null) {
        LocalStorage.setItem(storeFilterKey, CRUD_PAGER_FACTORY({
            exchanges: [-1],
            signal_subscription_types: [-1],
        }));

        storeFilters = LocalStorage.getItem(storeFilterKey);
    }

    // filters
    const filters = reactive({
        ...storeFilters,
    });
  
    const filtersChanged = computed(() => {
        return filters.filters.exchanges[0] !== -1
          || filters.filters.signal_subscription_types[0] !== -1;
    });
    const resetFilters = () => {
        filters.filters.exchanges = [ -1 ];
        filters.filters.signal_subscription_types = [ -1 ];

        getData();
    };
  
    const filtersSignalsDetails = reactive(CRUD_PAGER_FACTORY({
        signals: [-1],
    }));

    const changeFilter = ([ key, value ], update = false) => {
        const canUpdate = key === 'query' && !value && dataRef.value.pager.query;

        if (key !== 'page') {
            _.set(filters, 'page', 1);
        }

        if (key === 'perPage') {
            LocalStorage.setItem('__filters_marketplace-my-signals', value);
        }

        _.set(filters, key, value !== null && value !== undefined ? value : [ -1 ]);
      
        if (update || canUpdate)
            getData();
    };

    const changeFilterDetails = ([ key, value ], update = true) => {
        if (key === 'perPage') {
            _.set(filtersSignalsDetails, 'page', 1);
        };

        _.set(filtersSignalsDetails, key, value !== null && value !== undefined ? value : [ -1 ]);
      
        if (update)
            getSignalsDetails();
    };

    const getData = async () => {
        gl.showLoading = true;

        // save filters
        LocalStorage.setItem(storeFilterKey, filters);

        try {
            const prepare = await Marketplace.getMySignals(filters);

            dataRef.value = prepare.data;
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };

        gl.showLoading = false;
    };

    const getSignalsDetails = async () => {
        gl.showLoading = true;

        if (!currentSignal.value) return;

        try {
            filtersSignalsDetails.filters.signals = [ currentSignal.value.id ];

            const prepare = await Marketplace.getSignalsDetails(filtersSignalsDetails);

            signalsDetails.value = { ...currentSignal.value, ...prepare.data };
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };

        gl.showLoading = false;
    };

    const refFilters = computed(() => [
        {
            mode: 'exchanges',
            title: refs.localization.filters.marketplace.filters_marketplace_exchanges_label,
            refs: refs.exchanges,
            filter: 'filters.exchanges',
            seeable: filters.filters.exchanges[0] !== -1,
        }, {
            mode: 'radio_group',
            title: refs.localization.filters.marketplace.filters_marketplace_subscription_type_label,
            refs: marketplace.signalSubscriptionTypes,
            value: filters.filters.signal_subscription_types,
            filter: 'filters.signal_subscription_types',
            seeable: filters.filters.signal_subscription_types[0] !== -1,
        },
    ]);

    const doSomething = ([ action, value ]) => {
        const fns = {
            showInfo: async () => {
                currentSignal.value = value;
                await getSignalsDetails();

                showInfoModal.value = true;
            },
        };

        fns[action]();
    };

    const onRebillSignal = async id => {
        gl.showLoading = true;

        try {
            const prepare =  await Marketplace.signalsMyRebill(id);

            // show messages
            prepare.postMessages.forEach(el => {
                gl.showNotification({
                    type: el.success ? 'success' : 'error',
                    msg: el.msg,
                });
            });

            if (prepare?.data.status) {
                showInfoModal.value = false;
                await getData();
            }
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };
      
        gl.showLoading = false;

    };

    const currency = icon => refs.currencies.find(el => el.id === icon);

    onMounted(async () => {
        await getData();

        init.value = true;
    });

    return {
        gl,
        refs,
        init,
        filters,
        dataRef,
        iAcceptIt,
        refFilters,
        marketplace,
        showInfoModal,
        filtersChanged,
        signalsDetails,
        filtersSignalsDetails,
        getData,
        currency,
        doSomething,
        resetFilters,
        changeFilter,
        onRebillSignal,
        getSignalsDetails,
        changeFilterDetails,
    };
}